.u-hidden
    display none

.u-invisible
    visibility hidden

.u-visually-hidden
    border 0
    clip rect(0 0 0 0)
    margin -.0625rem
    overflow hidden
    padding 0
    position absolute
    size .0625rem
    &.is-focusable
        &:active,
        &:focus
            clip auto
            margin 0
            overflow visible
            position static
            size auto

.u-float-right
    float right

.u-float-left
    float left

.u-text-left
    text-align left

.u-text-center
    text-align center

.u-text-right
    text-align right

.u-text-ellipsis
    ellipsis()

.u-overflow-hidden
    overflow hidden

.u-uppercase
    text-transform uppercase

.u-negative-margin
    margin-left calc(var(--default-container-margin) * -1)
    margin-right calc(var(--default-container-margin) * -1)

.u-full-width
    left 50%
    margin-left -50vw
    margin-right -50vw
    position relative
    right 50%
    width 100vw

for $num in 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100
    .u-margin-bottom-{$num}
        margin-bottom unit($num / DEFAULT_SIZE, rem)

    .u-margin-top-{$num}
        margin-top unit($num / DEFAULT_SIZE, rem)

    .u-padding-bottom-{$num}
        padding-bottom unit($num / DEFAULT_SIZE, rem)

    .u-padding-top-{$num}
        padding-top unit($num / DEFAULT_SIZE, rem)