// MOBILE DEVICES WITH VERTICAL ORIENTATION
// +portrait()

// MOBILE DEVICES WITH HORIZONTAL ORIENTATION
// +landscape()

// UNTIL
// +until(980px)
// +until(980px, 'height')

// FROM
// +from(980px)
// +from(980px, 'height')

// BETWEEN
// +between(100px, 200px)
// +between(100px, 200px, 'height')

// RETINA
// retina()

// * BREAKPOINTS
// edit BREAKPOINTS IN ./dev/includes/stylus/base/_vars.styl

// ** DEFAULT VALUES
// $breakpoints.phone
// $breakpoints.tablet
// $breakpoints.desktop
// $breakpoints.large-desktop

+from($breakpoints.tablet + 1)
    .u-hide-in-desktop
        display none !important

+until($breakpoints.tablet)
    .u-hide-in-mobile
        display none !important

    for $num in 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100
        .u-margin-bottom-{$num}
            margin-bottom var(--default-margin)

        .u-margin-top-{$num}
            margin-top var(--default-margin)

        .u-padding-bottom-{$num}
            padding-bottom var(--default-margin)

        .u-padding-top-{$num}
            padding-top var(--default-margin)