// BLOCK MIXINS
from($size, $orientation = 'width')
    @media screen and (min-{$orientation}: $size)
        {block}

until($size, $orientation = 'width')
    @media screen and (max-{$orientation}: $size)
        {block}

between($min, $max, $orientation = 'width')
    @media screen and (min-{$orientation}: $min) and (max-{$orientation}: $max)
        {block}

retina()
    @media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi)
        {block}

portrait($width = 500px)
    @media screen and (max-width: $width) and (orientation: portrait)
        {block}

landscape($width = 500px)
    @media screen and (max-width: $width) and (orientation: landscape)
        {block}