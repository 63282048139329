$reset
    border 0
    margin 0
    padding 0

$google-maps
    a[href^="http://maps.google.com/maps"]
        display none !important

    .gmnoprint
        a,
        span
            display none

    .gm-style-cc
        display none

$horizontal-scrolled
    display flex
    justify-content flex-start
    overflow-x auto
    white-space nowrap
    &__item
        flex 0 0 auto