*,
*:before,
*:after
    box-sizing border-box
    outline 0

html,
body
    max-width 100%
    min-height 100vh

html
    font-size 100%
    line-height var(--default-line-height)
    overflow-x hidden
    scroll-behavior smooth

body
    -webkit-font-smoothing antialiased
    color var(--default-text-color)
    font-family var(--default-font-family)
    font-size (DEFAULT_SIZE * 1px)
    margin 0
    position relative

::selection
    background var(--default-selection-bg)
    color white
    text-shadow none

a
    color inherit
    cursor pointer
    text-decoration none

img
    height auto
    max-width 100%

audio
canvas
iframe
img
svg
video
    vertical-align middle

figure
fieldset
    @extends $reset

h1
h2
h3
h4
h5
h6
    line-height var(--default-line-height)

hr
    border 0
    border-top .0625rem solid #dedede
    display block
    height .0625rem
    margin 1rem 0
    padding 0

mark
    background transparent
    color inherit
    font-weight 700

button
input
optgroup
select
textarea
    font-family var(--default-font-family)

textarea
    resize vertical

.mh
    align-items flex-start
    display inline-flex
    flex-wrap wrap

.mr
    list-style none
    margin-bottom 0
    margin-top 0
    padding-left initial

.responsive-image
    display block
    height auto
    max-width 100%

.responsive-image--full
    @extends .responsive-image
    width 100%

.responsive-video
    height 0
    overflow hidden
    padding-bottom 56.25%
    position relative
    iframe
    object
    embed
    video
        absolute top left
        border 0
        size 100%

.container
    margin-left auto
    margin-right auto
    max-width calc(var(--default-container-width) + (var(--default-container-margin) * 2))
    padding-left var(--default-container-margin)
    padding-right var(--default-container-margin)
    width 100%