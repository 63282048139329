body
    align-items center
    background-color #307c78
    background-image url($img"bg.jpg"), linear-gradient(top, #24625f, #307c78)
    background-position bottom center, center
    background-repeat no-repeat, repeat
    background-size cover
    color white
    display flex
    flex-direction column
    justify-content center
    padding-top 1.5em
    padding-bottom 1.5em

.main-logo
    align-items center
    background-color #135451
    display flex
    justify-content center
    overflow hidden
    position relative
    size 16.75em
    transition .3s ease-out
    &__image
        -ac()
        width 80%

.main-player
    text-align center
    display flex
    align-items center
    justify-content center
    flex-direction column
    &__button
        size 2.3em
        border-radius 50%
        display flex
        align-items center
        justify-content center
        border 0
        background-color #135451
        color white
        font-size 1.7em
        cursor pointer
        box-shadow 0 0 .5em rgba(black, .15)
        margin-top 1em
    &__logo
        margin-bottom 1.5em
    &__status
    &__title
        font-size 1.3em
        text-shadow 1px 1px 3px rgba(black, .5)
    &__status
        margin-bottom .3em
    &__title
        font-weight 700

.main-player-volume
    display flex
    align-items center
    justify-content flex-start
    margin-top 1.5em
    &__icon
        font-size 2em
        margin-right .5em
    &__slider
        width 12em

.ui-slider
    .ui-slider-handle
        cursor pointer
        border-radius 100%
        top 50%
        transform translateY(-50%)

.ui-widget.ui-widget-content
    border-color #135451

.ui-widget-header
    background #135451

.ui-slider-horizontal
    height .5em

#api
    position absolute
    top 0
    left 0
    visibility hidden
    pointer-events none


@media screen and (max-width: 425px)
    body
        font-size .8em

    .main-logo
        width 13em
        padding-bottom 0
        height 13em